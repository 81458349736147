.stepper-container-merch {
    padding: 0 20px;
    overflow-x: auto;
    margin-top: 20px;
}
.stepper-container-merch .stepper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.stepper-container-merch .stepper span.line {
    border-bottom: solid 2px #ccc;
    flex: 0 0 70px;
    position: relative;
    top: -4px;
    right: -7px;
    min-width: 20px;
}
.stepper-container-merch .stepper span.step {
    background: #333;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.stepper-container-merch .stepper span svg {
    fill: white;
    font-size: 48px;
}
.stepper-container-merch .stepper span.line + svg {
    fill: #ccc;
    font-size: 2rem;
    position: relative;
    top: -4px;
    left: -7px;
    min-width: 32px;
}
.stepper-container-merch .stepper span.step.active {
    background: var(--primary-color);
}
