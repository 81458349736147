@font-face {
    font-family: 'Roboto'; 
    font-weight: 300; 
    src: url('./fonts/roboto-light-webfont.woff2') format(woff2),
          url('./fonts/roboto-light-webfont.woff') format(woff);
    font-display: swap;
}
  
* {    
    font-family: 'Roboto', sans-serif; 
    margin: 0;
    padding: 0;
    box-sizing: border-box;  
    letter-spacing: 0.07em; 
    caret-color: transparent;    
}

:root {   
    --header-height: 64px;
    --primary-color: #06f;
    --primary-color-dark: #0066CC;
    --primary-color-light: #7eb1fc;

    --wrapper-padding: 40px;

    --secondary-color: #88744a;
    --secondary-color-dark: #72613e;
    --secondary-color-light: #aa8e52;

    --tertiary-color: #001436;

    --cta-green: #13ae4b;
    --cta-green-dark: #0B652B;
    --cta-discount: #F0FDF0;
    --cta-discount-two: #E1FAE1;
    --cta-information: #DFF5F5;
    --cta-required: #FCECEE;
    --cta-processing: #FF3D33;
    --cta-red: red;
    --cta-red-dark: #da0303;
    --cta-blue: var(--tertiary-color);
    --cta-stripe: #6772e5;
    --cta-stripe-two: #5d67d4;

    --speed: 300ms;   
    --transition-speed: 500ms;   
    --icon-font-size: 1.5rem;    
  
    --icon-color: var(--secondary-color);
    --icon-color-hover: var(--secondary-color-dark);
    --icon-button-background: rgba(0,0,0,0.03);
    --icon-button-background-hover: rgba(0,0,0,0.05);

    --link-text-color: #111;
    --link-text-color-hover: #999;

    --foreground: #222;
    --background: #f1f2f6;
    --card-background: #fff;
    --widget-background: #fff;
    --dropdown-background: #fff;

    --tooltip-background: #333;
    --tooltip-text-color: #fff;

    --footer-text-color: #111;

    --box-shadow: 0 14px 18px 5px #00000011;
    --border-color: var(--input-border-color);  

    --button-disabled-background: #ddd;
    --button-disabled-color: #bbb;
    --button-text-color: #fff;

    --table-head-background: #eee;
    --table-border-color: #fff;
    --alternate-rows: #f9f9f9;
    --table-row-open: #555;
    --reverse-border-color: #444;   

    --input-background-color: #f8f8f8;
    --input-background-color-hover:#fff;
    --input-text-color: #333333;
    --input-border-color: #ccc;   
    --placeholder-color: #333; 
    
    --select-background-color: #fff;
    --select-background-color-hover: #f1f2f6;

    --richtext-toolbar-background: #fff;
    --richtext-toolbar-buttons: #f1f2f6;
    
    --transparent-background: rgba(255,255,255,1);

    --modal-background: #fff;

    --paragraph-text-color: #333;

    --h1-color: #4F4F4F;
}  

body {
    color: var(--foreground);   
    transition: all var(--speed);
    min-height: 100vh;
}

.background-panel {
    background: var(--card-background);
    transition: all var(--speed);
}

.background {
    background: var(--card-background); 
}

.box-shadow {
    box-shadow: var(--box-shadow);
}

#root {
    display: flex; 
    flex-direction: column;    
    min-height: 100vh;  
      
}

main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;  
    position: relative;   
    min-height: calc(100vh - 100px);  
}

/* Layout Styles */   
.container {
    width: 100%;
    max-width: 1100px;
    
    margin: 0 auto;
}
.wrapper {
    width: 100%; 
    padding: 40px;  
}
.wrapper-x {
    padding: 0 40px;  
}

.parent,
.mobile-parent,
.parent-medium,
.parent-large {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    position: relative;
} 

h1 { 
  font-size: 32px;  
  font-weight: 300;
  letter-spacing: 0.05em;
}
h1 .icon {
  font-size: 2rem;
}
h2 {
    margin-bottom: 40px;
    font-weight: 300;
    font-size: 24px;
}
h3, 
.line-thru {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
h3 {
  margin: 20px 0;
}
h3::before,
h3::after,
.line-thru:before,
.line-thru:after {
  content: "";
  flex: 1;
  height: 1px; 
  background: linear-gradient(to left, #ccc, rgba(0,0,0,0));
}
h3::after,
.line-thru:after {
  background: linear-gradient(to right, #ccc, rgba(0,0,0,0));
}
.large-header {
    color: var(--h1-color);
    margin: 20px 0;
    font-size: 40px;
    font-size: clamp(30px, 4vw, 40px);
}
h4 {
    font-size: 14px;
    color: #999;
    margin: 15px 0;
}
.widget-header {
    margin-bottom: 20px;
    margin-top: 0;
    color: orange;
    font-size: 18px;
    padding: 32px 0;
    border-radius: 10px;
    text-align: center;
    background: #001436;
}
h5 {
    color: var(--h1-color);
}
h6 {
    color: var(--foreground);
   font-weight: 300;
}
  
p {
    line-height: 1.8rem;
    color: var(--paragraph-text-color);
    font-size: 14px;
    transition: all var(--speed);
}
.paragraph-text {
  max-width: 800px; 
  margin: 0 auto;
}
.reviews p {
  color: #ccc;
}

.comment p {
  font-size: 12px;
}

small {
    font-size: 12px;
    transition: all var(--speed);
}

hr {
  height: 1px; 
  background: linear-gradient(to right, transparent, #ccc, transparent);
  border: none;
  margin: 40px 0; 
}

i, 
.cursive {
    font-family: 'Monotype Corsiva', cursive;
}

ul {
    list-style: none;
}
.bullet-list {
    list-style-type: disc;
    list-style-position: inherit;
    padding-left: 20px;
}
li {
    font-size: 14px;
    transition: all var(--speed);
}
li::marker {
    color: var(--icon-color);
    font-size: 1rem;
}
.largetext p,
.largetext li,
.largetext small {
  font-size: 16px;
}

a {
    color: var(--link-text-color);
    text-decoration: none;
    transition: all var(--speed);
}  
a:hover {
    color: var(--link-text-color-hover);
} 
.page-link {
    color: var(--primary-color);   
    font-size: 14px; 
    font-weight: bold;
}
.page-link:hover {
    color: #999;    
}
.link {
    color: var(--primary-color);
    font-weight: bold;
    transition: all var(--speed);
    padding: 2px 5px;
    border-radius: 3px;
    cursor: pointer;
}

.link svg {
    color: inherit;
}

.link:hover {
    background: rgb(203, 227, 247);
    color: var(--primary-color-dark);
}
  
img {   
    width: 100%; 
    height: auto; 
    display: inline-block;  
} 
figcaption {
    font-size: 12px;
}
.hover-zoom {
    overflow: hidden;
    border-radius: 10px;
}
.hover-zoom img {
    transition: all 800ms;
}
.hover-zoom:hover img {
    transform: scale(1.1);
}
.background-cover {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.object-fit {
    height: 100%;
    width: 100%;
    object-fit: cover;
} 
.lazy-load-image-background {
    height: 100% !important;
    width: 100% !important;
    margin: 0 auto !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;   
}
.main-banner {    
    margin-top: calc(-1 * var(--header-height));
    position: relative;
    background: #333;
    overflow: hidden;
}
.main-banner h1 {   
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 4px #000;    
  font-size: clamp(40px, 8vw, 80px);  
  text-transform: uppercase;
  line-height: 1.2em;
}
.main-banner h2 {   
  margin-bottom: 0; 
  text-shadow: 1px 1px 4px #000;
  color: gold;
  text-align: right;
  font-size: clamp(24px, 4.5vw, 42px);
  font-weight: bold;
  line-height: 1em;
}

  /* Tables */
.table-container {
    border-radius: 10px;
    padding: 20px; 
    background: var(--background);
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;    
}
table.borderless th,
table.borderless td {
  border: none;
}
th,
td {
    font-size: 12px; 
    vertical-align: middle;
    padding: 5px 10px;
    text-align: left;    
    border: solid 1px var(--table-border-color);
    word-wrap: break-word;
} 
.slim-table th,
.slim-table td {
    padding: 0 10px;
} 
.widget-table {
    background: white;
    border-radius: 10px;
    overflow: hidden;
}
.widget-table th,
.widget-table td {
    border-bottom: solid 1px var(--border-color);
}

/* Scrollbar Styles */
::-webkit-scrollbar {
    width: 12px; 
    height :12px;
}  
::-webkit-scrollbar-track-piece {
    background: var(--background);
    /* background: red; */
} 
::-webkit-scrollbar-button {
    background:#ccc;
    background:var(--input-border-color);
}
::-webkit-scrollbar-thumb {
    background: var(--icon-color-hover); 
    border-radius: 10px; 
    border: solid 3px var(--background);
}
::-webkit-scrollbar-thumb:hover {
    background: #999; 
    opacity: 0.5;
}

  /* Alert Styles */
#__react-alert__ {
  position: relative;
  z-index: 9999;
}
#__react-alert__ > div > div > div {  
  text-transform: capitalize !important;  
  border-radius: 10px !important;
  padding: 16px 20px !important;
  position: relative;  
}
#__react-alert__ button {
  position: absolute;
  top: 10px;
  right: 10px;
}
#__react-alert__ button svg {
  stroke: #999 !important;    
}
  
/* Helper (BootStrap) Classes */
  .d-flex {
    display: flex;
  }
  .gap-1 {
    gap: 10px;
  }
  .gap-2 {
    gap: 20px;
  }
  .gap-4 {
    gap: 40px;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .justify-content-around {
    justify-content: space-around;
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-end {
    justify-content: flex-end;
  }
  .justify-content-start {
    justify-content: flex-start;
  }
  .direction-column {
    flex-direction: column;
  }
  .align-items-center {
    align-items: center;
  }
  .align-items-end {
    align-items: flex-end;
  }
  .relative {
    position: relative;
  }
  .absolute {
    position: absolute;
    top: 0;
    left: 0;
  }
  .block {
    display: block;
  }
  .inline-block {
    display: inline-block;
  }
  .fixed {
    position: fixed;
    top: 0;
    left: 0;
  }
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  .whitespace-nowrap {
    white-space: nowrap;
  }
  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .zoom-in {
    cursor: zoom-in;
  }
  .scroll-x {
    overflow-x: auto;
  }
  .scroll-y {
    overflow-y: auto;
  }
  .fullWidth {
    width: 100%;
    max-width: none !important;
  }
  .fullHeight {
    height: 100%;
  }
  .screenheight {
    height: 100vh;
  }
  .float-r {
    float: right;
  }
  .hidden {
    display: none;
  }
  .round {
    border-radius: 50%;
    overflow: hidden;
  }
  .mobile, .mobile-button-large, .icon-button.mobile-button-large, .icon-button.mobile {
    display: none;
  }
  .danger {
    color: var(--cta-red);
  }
  .success {
    color: var(--cta-green-dark);
  }
  .processing, 
  .info {
    color: var(--cta-blue);
  } 
  
  @media ( max-width: 1365px ) {
    .parent-large {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
    .parent-large .widget-header {      
      border-radius: 10px !important;
    }
    .mobile-button-large, 
    .icon-button.mobile-button-large {
      display: block;
    }
  }

  @media ( max-width: 900px ) {
    .parent-medium {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
  
  @media ( max-width: 768px ) {      
    .wrapper {
      padding: 30px;
    }
    :root {
      --wrapper-padding: 30px;
      --transition-speed: 300ms;
    }
    .wrapper-x {
      padding: 0 30px;
    }
    .parent {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    } 
    .reverse {
      flex-direction: column-reverse;
    }
    .flex-mobile {
      flex-direction: row;
    }
    .mobile-flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .mobile-gap {
      gap: 40px;
    } 
    .paragraph {
      padding-left: 40px;
      padding-right: 40px;
    }
    .mobile-center {
      text-align: center;      
    }  
    .mobile-column {
      flex-direction: column;
    }
    .mobile-fullwidth {
      width: 100% !important;
    }
    .mobile, .icon-button.mobile {
      display: block;
    } 
    .widget-header {      
      border-radius: 10px !important;
    }
  }
  @media ( max-width: 601px ) {   
    .flex-mobile {
      flex-direction: column;
    } 
    .mobile-reverse {
      flex-direction: column-reverse !important;
    }
    header nav > div:last-child {
        flex: none !important;
    }
    .large-screen {
      display: none;
    } 
    .wrapper {
      padding: 30px 20px;   
    }
    .wrapper-x {
      padding: 0 20px;
    }
    :root {
      --wrapper-padding: 20px;
    }
    .mobile-parent {
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
    }
    .mobile-center {
      align-items: center;
    }  
    .mobile-text-left {
      text-align: left;
    }
    .booking-mobile-fullwidth {
      width: 100%;
      max-width: none !important;
    }
    .mobile-text-right {
      text-align: right;
    }
  }

  @media ( max-width: 501px ) {  
    :root {
        --transition-speed: 250ms;
    }
}

  @media ( max-width: 401px ) {   
    .hide-mobile {
      display: none !important;
    }
  }  