#modal-root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 6;
}
.modal {
    float: right;
    width: 100%;
    position: relative;
    z-index: 2;
    background: var(--card-background);
    overflow-y: auto;
    max-width: 480px;
    border-top-left-radius: 20px;    
}
.modal::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100px;
    background: var(--tertiary-color);
    top: 0;
    left: 0;
    z-index: -1;
}
.modal.fullWidth::before {
    display: none;
}
.modal.fullWidth {
    background: rgba(0,0,0,0.8);
    border-radius: 0;
}
.modal h3 {
    color: orange;
    font-size: 32px;
    margin: 0 0 60px 0;
    font-weight: 300;
}
.modal .cart-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: #EB0000; 
    transform: rotate(45deg);
    width: 100%;   
    top: 50px; 
    right: -150px;
    gap: 10px;
    color: white;
    padding: 10px 20px;
    font-weight: bold;
    z-index: 1;
}
.modal > .icon-button {
    position: absolute; 
    top: 0;
    right: 0;   
}
.modal > div > .icon-button {
    position: absolute;
}
.darkmode .modal {
    background: #111;
}
.modal .icon {
    color: #ccc;
}

@media (max-width: 601px) {
    .modal {   
        border-radius: 0;    
    }
    .modal h3 {
        font-size: 28px;
    }      
}

@media (max-width: 400px) {   
    .modal .cart-banner {
        display: none;
    }

}
