header {
    position: relative;
    width: 100%;
    top: 0;      
    z-index: 3;
    transition: all 0.3s var(--speed);      
}  
header nav {
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-bottom: solid 1px var(--input-border-color);  
    background: var(--card-background);
    box-shadow: var(--box-shadow);
}
/* logo link patch */
header .link-patch {
    width: 100%;
    width: 190px;
    position: absolute;
    top: 0; 
}
header .link-patch span {
    height: 65px; 
    width: 190px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}
header nav.clear {
    border-color: transparent;   
    background: transparent;
    box-shadow: none;
}
header nav.clear .icon {
    color: white;
}
header nav.clear .icon-tag {
    color: white;
}
header nav > div {
    display: flex;    
    align-items: center;
    gap: 10px;    
}  
header .dropdown-menu {  
    position: absolute;
    top: 100%;
    right: 0;
    gap: 0.7rem;
    z-index: 3;
    background: var(--transparent-background);
    box-shadow: var(--box-shadow);
    border: solid 1px var(--border-color);
    border-bottom-left-radius: 15px;
    display: flex;
    flex-wrap: wrap;
}
nav.clear + .dropdown-menu {
    background: rgba(0,0,0,0.5);
    border: none;
}
nav.clear + .dropdown-menu a,
nav.clear + .dropdown-menu span,
nav.clear + .dropdown-menu svg {
    color: white;
}
header .dropdown-menu a,
header .dropdown-menu span {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 10px;
    text-align: right;
    white-space: nowrap;
    padding: 5px 10px;
    color: var(--link-text-color);
    transition: all var(--speed);
    cursor: pointer;
}
header .dropdown-menu a:hover,
header .dropdown-menu span:hover {    
    color: var(--link-text-color-hover);
}

.toggle {
    --width: 30px;
    --height: calc(var(--width) / 2);
    --border-radius: calc(var(--height) / 2);  
    display: inline-block;
    cursor: pointer;
}
.toggle-input {
    display: none;
}
.toggle-fill {
    position: relative;
    width: var(--width);
    height: var(--height);
    border-radius: var(--border-radius);
    background: #999;
    transition: background 0.2s;
}  
.toggle-input:checked ~ .toggle-fill {
    background: #ccc;
}  
.toggle-fill::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: var(--height);
    width: var(--height);
    background: var(--foreground);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: var(--border-radius);
    transition: transform 0.2s;
}  
.toggle-input:checked ~ .toggle-fill::after {
    transform: translateX(var(--height));
}

.nav-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px var(--border-color);
    padding: 8px;
}
.nav-link:hover {
    background: var(--cta-information);
    color: var(--primary-color);
}

@media ( max-width: 768px ) {  
    .logo-large {
        width: 100px;
    } 
    header .link-patch span {
        width: 100px;
    }
}
@media ( max-width: 400px ) {  
    header nav div.nav-icons .icon-button {
        min-width: 32px;
        min-height: 32px;
    }
    small.icon-tag {
        top: -12px;
        right: -12px;
    }
}

